.parent-menu-container {
  margin-left: calc(var(--sidebar-width) + 1rem);
}

.parent-menu-container .sidebar-menu-item {
  color: var(--primary-color);
  margin-bottom: 0.2rem;
  padding-bottom: 0;
}

.parent-menu-container .tappity-sub-header {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
  margin-top: 0.75rem;
}

.hidden {
  display: none;
}

.id-badge-container {
  margin: auto 0;
  position:relative;
  display:inline-block;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  background-color: #661cd4;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 1rem;
}

.display-selfie-image {
  border-radius: 50%;
  width: 65%;
  margin: 0;
  order: 1;
}

.user-profile-name {
  font-weight: bold;
  order: 2;
}
.user-level-progress-container {
  display: flex;
  background-color: #471c80;
  border-radius: 10rem;
  width: 90%;
  order: 3;
  font-weight: bold;
}

.user-level-progress {
  background-color: white;
  height: 80%;
  margin: auto 0.25rem;
  border-radius: 10rem;
  transition: width 2s;
}

.user-level {
  font-weight: bold;
  order: 4;
  text-align: center;
}


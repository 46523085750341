#progress-page {
    color: black;
    padding-bottom: 1px;
}

#progress-page .progress-section {
    border-radius: 0.5rem;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 14%);
    overflow: hidden;
    background-color: white;
    margin: 1.5rem 0;
    padding: 1rem;
}

#progress-page nav  {
    position: relative;
}

#progress-page .tappity-select-wrapper {
    position: absolute;
    top: 0;
    right: 0;
}

#progress-page .summary .user-profile-photo {
    width: 6rem;
    height: 6rem;
    border-radius: 3rem;
    float: left;
    margin-right: 2rem;
}

#progress-page .summary h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
}

#progress-page .summary .topics {
    max-width: 800px;
}

#progress-page h4 {
    text-transform: uppercase;
    margin-top: 0;
}

#progress-page .topics-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

#progress-page .topic {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

#progress-page .topic:hover {
    background-color: #eee;
}

#progress-page .topic-title {
    font-weight: bold;
    font-size: 14pt;
    margin: 0.25rem 0;
}

#progress-page .progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #ABC6C2;
    border-radius: 10px;
    overflow: hidden;
    margin: 0.75rem 0;
}

#progress-page .progress-bar-value {
    background-color: #07675A;
    height: 20px;
    border-radius: 10px;
}

#progress-page .progress-summary span {
    font-size: 12pt;
    display: block;
}

#progress-page .progress-summary {
    font-size: 10pt;
}

#progress-page .creations-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
}

#progress-page .creations a {
    cursor: pointer;
}

#progress-page .creations img {
    width: 100%;
}

#topic-progress-page {
    padding-bottom: 1rem;

}

#topic-progress-page .story-list-wrapper {
    background: white;
    border-radius: 1rem;
    color: black;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    padding: 0 1.5rem 1.5rem 1.5rem;
}
#topic-progress-page .story-list-wrapper .empty-list {
    text-align: center;
    margin: 4rem;
}

#topic-progress-page nav {
    border-bottom: 1px solid #ccc;
    padding-top: 0.5rem;
}

#topic-progress-page nav button {
    appearance: none;
    color: var(--primary-color);
    text-transform: uppercase;
    background: none;
    border: none;
    padding: 1rem 0;
    margin: 0 1rem 0 0;
    font-weight: bold;
    cursor: pointer;
}

#topic-progress-page nav button.active {
    border-bottom: 4px solid var(--primary-color);
}

#topic-progress-page .story-item {
    border-bottom: 1px solid #eee;
    padding: 1rem 0;
    position: relative;
    cursor: pointer;
}
#topic-progress-page .story-item:hover {
    background-color: #fcfcfc;
}
#topic-progress-page .story-item h4 {
    margin: 0;
}
#topic-progress-page .story-item h6 {
    font-weight: normal;
    color: #aaa;
    margin: 0.3rem 2rem 0.3rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#topic-progress-page .story-item button {
    appearance: none;
    background: none;
    border: none;
    color: var(--primary-color);
    position: absolute;
    right: 0;
    top: 0.5rem;
    font-size: 24pt;
    display: block;
    padding: 0.5rem;
}

#story-player {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.02);
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.node-container {
  display: flex;
  flex-flow: row nowrap;
  background-color: #fdf8ef;
}

.node-container > .node-video-container {
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-radius: 1rem;
  margin: auto 0;
  height: 98%;
  background-color: chartreuse;
  flex-shrink: 0;
  flex-grow: 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.14);
}

.node-container > .phone-call-image {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.phone-call-image > .phone-profile-image {
  flex: 0 0 50%;
  width: 57%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.phone-call-image > .character-title {
  padding: 0rem 1rem 0.5rem 1rem;
  font-size: 250%;
  font-weight: 900;
}
.phone-call-image > .incoming-call {
  padding: 0rem 1rem 0.5rem 1rem;
  font-size: 250%;
}

.node-container > .node-interaction-panel {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 12px 50px rgba(0, 0, 0, 0.15);
  margin: 80px;
  /* how to display the column */
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: calc(calc(var(--node-option-width) * 1.5) + 3em);
  width: var(--node-option-width);
  /* how to display it's children */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.node-interaction-panel > .option-item {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: var(--node-option-width);
  width: var(--node-option-width);
  background-size: cover;
  border-radius: calc(var(--node-option-width) * 0.5);
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
}

/* must by last */
.hidden {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #story-player {
    background: black;
  }

  .live-story-player {
    margin-left: 0px;
    width: 100%;
    height: 90%;
    margin: auto 0px;
    border-radius: 0px;
    background: black;
    max-width: 100%;
  }

  .points-counter {
    top: 0px;
    left: 50%;
    position: absolute;
    width: 25%;
    display: none;
  }

  .live-story-player video {
    height: 100%;
    margin-top: 0px;
    width: 100%;
    background: #000;
  }

  .live-story-player .node-container {
    height: 50%;
    margin-top: -84%;
  }
}

.step-indicator {
    margin: 1rem 0 auto;
    font-weight: 600;
    text-align: center;
    opacity: 0.6;
  }
  .onboarding-top-nav {
    margin-top:3rem;
    padding: 0 1rem;
  }

  .onboarding-top-nav .sign-out-button {
    float: right;
    font-weight: 500;
    font-size:0.9rem;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .step-indicator {
      margin: 1.5rem 0 auto;
    }
    .onboarding-top-nav {
      margin-top:4rem;
      margin-bottom:2rem;
      padding: 0 1rem;
    }
  }
  
:root {
  --chip-body-height: 10rem;
  --chip-body-width: calc(var(--chip-body-height) * (50 / 63));
}

.chip {
  z-index: 100;
  position: fixed;
  top:30px;
  left:300px;
  zoom: 0.7;
}

.chip-body {
  position: absolute;
  background-image: url(assets/spud-body.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 90;
  height: var(--chip-body-height);
  width: var(--chip-body-width);
}

.chip-face {
  position: absolute;
  height: calc(var(--chip-body-height) / 6);
  width: 100%;
}

.chip-eyes {
  position: absolute;
  top: calc(var(--chip-body-height) * 0.23);
  width: 100%;
  height: calc(var(--chip-body-height) / 2.8);
  z-index:85;
}

.chip-left-eye {
  position: absolute;
  top: 0;
  left: calc(var(--chip-body-height) / 13);
  background-image: url(assets/spud-left-eye.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
}

@keyframes left-eye-blinking {
  0%,
  100% {
    background-image: none;
  }
  12.5% {
    background-image: url(assets/spud-left-eye-lid-blink-frame0.png);
  }
  25% {
    background-image: url(assets/spud-left-eye-lid-blink-frame1.png);
  }
  37.5% {
    background-image: url(assets/spud-left-eye-lid-blink-frame2.png);
  }
  50% {
    background-image: url(assets/spud-left-eye-lid-blink-frame3.png);
  }
  62.5% {
    background-image: url(assets/spud-left-eye-lid-blink-frame2.png);
  }
  75% {
    background-image: url(assets/spud-left-eye-lid-blink-frame1.png);
  }
  87.5% {
    background-image: url(assets/spud-left-eye-lid-blink-frame0.png);
  }
}

.chip-left-eye-lid {
  position: absolute;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.chip-left-eye-is-blinking {
  animation: left-eye-blinking 1.0s 1;
}


.chip-right-eye {
  position: absolute;
  top: 0;
  left: calc(var(--chip-body-height) * (22 / 60));
  background-image: url(assets/spud-right-eye.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
}

@keyframes right-eye-blinking {
  0%,
  100% {
    background-image: none;
  }
  12.5% {
    background-image: url(assets/spud-right-eye-lid-blink-frame0.png);
  }
  25% {
    background-image: url(assets/spud-right-eye-lid-blink-frame1.png);
  }
  37.5% {
    background-image: url(assets/spud-right-eye-lid-blink-frame2.png);
  }
  50% {
    background-image: url(assets/spud-right-eye-lid-blink-frame3.png);
  }
  62.5% {
    background-image: url(assets/spud-right-eye-lid-blink-frame2.png);
  }
  75% {
    background-image: url(assets/spud-right-eye-lid-blink-frame1.png);
  }
  87.5% {
    background-image: url(assets/spud-right-eye-lid-blink-frame0.png);
  }
}
.chip-right-eye-lid {
  position: absolute;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.chip-right-eye-is-blinking {
  animation: right-eye-blinking 1.0s 1;
}

@keyframes breathing {
  0%,
  100% {
    background-image: url(assets/spud-mouth-breathing-frame0.png);
  }
  10% {
    background-image: url(assets/spud-mouth-breathing-frame1.png);
  }
  20% {
    background-image: url(assets/spud-mouth-breathing-frame2.png);
  }
  30% {
    background-image: url(assets/spud-mouth-breathing-frame3.png);
  }
  40% {
    background-image: url(assets/spud-mouth-breathing-frame4.png);
  }
  50% {
    background-image: url(assets/spud-mouth-breathing-frame4.png);
  }
  60% {
    background-image: url(assets/spud-mouth-breathing-frame4.png);
  }
  70% {
    background-image: url(assets/spud-mouth-breathing-frame3.png);
  }
  80% {
    background-image: url(assets/spud-mouth-breathing-frame2.png);
  }
  90% {
    background-image: url(assets/spud-mouth-breathing-frame1.png);
  }
}

.chip-mouth {
  position: absolute;
  width: 100%;
  top: calc(var(--chip-body-height) / 10);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 91;
  height: calc(var(--chip-body-height) / 1.1);
}

.chip-is-breathing {
  animation: breathing 3.25s infinite;
}

@keyframes rotate-propeller {
  0%,
  100% {
    background-image: url(assets/spud-propeller-frame0.png);
  }
  33% {
    background-image: url(assets/spud-propeller-frame1.png);
  }
  66% {
    background-image: url(assets/spud-propeller-frame2.png);
  }
}

.chip-propeller {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  top: calc(var(--chip-body-height) * 0.82);
  z-index: 80;
  height: calc(var(--chip-body-height) * 0.9);
  width: var(--chip-body-width);
  animation: rotate-propeller 0.25s infinite;
}

.chip-mouth > canvas {
  z-index: 2000;
}

.hidden {
  display: none;
}

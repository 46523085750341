.user-profile-card {
  color: black;
  background-color: #e4dbec;
  border-color: #e4dbec;
  border-style: solid;
  border-width: 0.15rem;
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-height: 3rem;
  margin: 0.5rem;
  cursor: pointer;
}

.selected-profile-card {
  border-color: var(--primary-color);
  background-color: white;
  border-width: 0.15rem;
  border-style: solid;
  cursor: default;
}
.user-profile-photo-name {
  display: flex;
  flex-flow: row nowrap;
}

.user-profile-details {
}

.user-profile-name {
}

.user-profile-age-group {
}

.user-profile-photo {
  background-image: url(assets/abstract-user-flat-4.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 3rem;
  margin-right: 0.5rem;
  border-radius: 1.5rem;
}

.edit-user-profile {
  color: white;
  background-color: var(--primary-color);
  align-self: flex-start;
  padding: 0.25rem;
  border-radius: .25rem;
  cursor: pointer;
}

.step-indicator {
  color:#fff;
  margin: 1rem 0 auto;
  font-weight: 600;
  text-align: center;
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .step-indicator {
    margin: 1.5rem 0 auto;
  }
}

.membership-details-container {
  margin-left: var(--sidebar-width);
  color: var(--dark-text-color);
}

.membership-header {
  color: var(--dark-text-color) !important;
}

.membership-details {
  padding: 1rem;
}
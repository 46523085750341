

.yellow-option {
  background-color:var(--yellow-color);
}

.blue-option {
  background-color:var(--blue-color);
}

.option-votes-container {
  display:inline-block;
  position:relative;
  margin:30px 0px 0px 30px;
  vertical-align: text-bottom;
}

.poll-question-results .option-1-votes-head {
  background-color: var(--yellow-color);
}

.poll-question-results .option-2-votes-head {
  background-color: var(--blue-color);
}

.poll-question-results .option-1-votes-body {
  background-color: var(--yellow-color);
}

.poll-question-results .option-2-votes-body {
  background-color: var(--blue-color);
}
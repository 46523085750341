.full-node-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  z-index: 1000;
  margin-top: -175%;
  position: relative;
}

.badge-container {
  display: block;
  position: relative;
  margin: 0px auto;
  margin-top: -64%;
  
}

.live-story-player
  .full-node-container
  .live-node-interaction-panel
  .option-item {
  position: relative;
  display: flex;
  justify-content: center;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 20%;
  height: 30%;
  margin: 40% 40% 0% 40%;
  z-index: 1200;
  border-radius: 50%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  background: var(--green-color);
}

.live-story-player .full-node-container .live-node-interaction-panel .centered-option {
  margin: 45% 40% 0% 40% !important;
}

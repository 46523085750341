.points-counter {
  position: relative;
  padding: 8px 20px;
  top: var(--node-container-top);
  left: var(--node-container-left);
  z-index: 2000;
  text-align: center;
  border-radius: 1rem;
  font-size: 200%;
  background-color: var(--primary-color);
  height: 60px;
  display:inline-block;
  font-weight:800;
  margin:1rem;
  right:0px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.2);
}

.points-counter p {
  font-size: 40%;
  margin-top:-0.08rem;
  color:#fff;
  opacity:0.8;
}


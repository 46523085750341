:root {
  --node-option-width: 11rem;
  --node-option-height: 11rem;
  --node-option-margin: 0.5rem;
  --node-option-column-count: 1;
  --node-video-container-width: 0px;
  --node-container-top: 0px;
  --node-container-left: 0px;
  --primary-color: #5931ea;
  --light-primary-color: #F1EDFF;
  --beige-color:#FCF7ED;
  --dark-text-color:#000;
  --yellow-color:#FFD106;
  --blue-color:#08D3F2;
  --red-color:#FF2600;
  --green-color:#13C325;
  --sidebar-width: 13.125rem;
  --light-text-color: #5e5e5e;
}

a {
  text-decoration: none;
}
.back-button {
  background: var(--primary-color);
  border-radius: 1rem;
  padding: 0px 0.7rem;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
  color:#fff;
}
body {
  font-family: -apple-system-body, BlinkMacSystemFont, sans-serif;
  background-color: #F4F0FF;
  color: #ffffff;
  margin: 0;
}

/* html,
body {
  width: 100vw;
  height: 100vh;
} */


  
  .email-password-auth-form-container .tappity-form {
    margin: 0.5rem auto;
  }
  
  
  .registration-sign-in-link {
  }
  
  .reset-email-info {
    font-size: 110%;
    max-width: 15rem;
    padding: 1rem;
    border-radius: 0.9rem;
  }
  
  .reset-email-success {
    color: #164b00;
    background-color: #e0f1d8;
  }
  
  .reset-email-error {
    color: #4b0000;
    background-color: #f1d8d8;
  }
  
  @media only screen and (min-width: 768px) {
    .email-password-auth-form-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      background:white;
      width: 40%;
      margin:2rem auto;
      padding:2rem 0 4rem 0;
      box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
      border-radius: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .email-password-auth-form-container {
      align-items: left;
      background:white;
      width: calc(100% - 2rem);
      margin:1rem auto;
      padding:2rem 0 2rem 0;
      box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
      border-radius: 1rem;
    }
  }
.class-carousel {
  margin-top: 2rem;
}

.class-carousel .scroll-left {
  left: -1.5rem !important;
}
.class-carousel .scroll-right {
  right: -1.5rem !important;
}

.class-carousel .class-item {
  width: 14rem;
  height: 20rem;
  margin: 0.8rem;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  border-radius: 0.25rem;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 14%);

  background-color: #fff;
  overflow: hidden;
}

.class-carousel .class-info {
  padding: 6% 7% 8% 7%;
  width: 87%;
  background: #fff;
  position: relative;
  margin-top: auto;
  text-align: left;
  font-size: 14pt;
}

.class-carousel .class-subtitle {
  margin-top: 0.5rem;
  color: var(--primary-color);
  font-weight: bold;
  opacity: 0.8;
}

.class-carousel .class-title {
  font-weight: bold;
  color: #000;
}

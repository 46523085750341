.email-password-auth-form-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.email-password-auth-form-container .tappity-form {
  
}
.reset-password-button {
  font-size: 1rem;
  background-color: white;
  font-weight: 500;
  color:var(--primary-color);
  border: none;

  margin: 0.8rem 0;
  cursor: pointer;
}

.registration-sign-in-link {
}

.reset-email-info {
  font-size: 110%;
  max-width: 15rem;
  padding: 1rem;
  border-radius: 0.9rem;
}

.reset-email-success {
  color: #164b00;
  background-color: #e0f1d8;
}

.reset-email-error {
  color: #4b0000;
  background-color: #f1d8d8;
}


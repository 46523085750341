.customer-support-details-container {
  margin-left: var(--sidebar-width);
  color: var(--dark-text-color);
}

.customer-support-header {
  color: var(--dark-text-color) !important;
}

.customer-support-details {
  padding: 1rem;
}

.customer-support-details-row {
  margin-top: 0.5rem;
}

.customer-support-row-header {
  font-weight: 700;
}
.story-details {
    width:250px;
    background:#fff;
    border-radius: 1rem;
    box-shadow: 0px 2px 15px rgba(0,0,0,0.1);
    padding:1rem 2rem 3rem 2rem;
    margin:0rem 1rem 1rem 1rem;
    height:auto;
}

.story-details ul {
    margin: 0px;
    padding-left:1.5rem;
}

.story-details li {
    margin-bottom: 10px;
}
.story-details p,  .story-details li {
    line-height: 1.4em;
}
@media only screen and (max-width: 768px) {
    .story-details {
        display:none;
    }
}
:root {
  --story-card-width: 12rem;
  --story-card-height: 22rem;
  --story-card-margin: 0.8rem;
}

.story-card {
  width: var(--story-card-width);
  height: var(--story-card-height);
  margin: var(--story-card-margin);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.14);
  overflow: hidden;
}
.story-card-info-container {
  width:86%;
  padding: 6% 7% 8% 7%;
  font-weight: 600;
  font-size: 1rem;
  background: #fff;
  text-align: left;
  height: auto;
}
.story-card-info-container .story-title {
  text-align: left;
  height: auto;
  color:#000;
}

.story-card > img.play-button {
  width: 3rem;
  height: 3rem;
  margin:auto auto;
}

.story-card-info-container > img.lesson-type-icon {
  width: 2.5rem;
  height: 2.5rem;
  margin:0.2rem;
  border-radius:50%;
}

.story-card-info-container img.profile-pic {
  
  width: 2.5rem;
  height: 2.5rem;
  margin:0.2rem 0.2rem 0.2rem 0;
  border-radius:50%;
}

.story-card.mini-story {
  background-color: var(--primary-color);
}

.story-card.checkpoint-story {
  background-color: orange;
}

.story-card.mini-story {
  background-size: 80%;
}

.story-card .story-header {
  padding: 0.5rem 1rem 0rem 1rem;
}

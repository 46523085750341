#sidebar {
  order: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;
  background-color:var(--primary-color);
  color: black;
  flex: 0 0 15rem;
  width: 20%;
  height: 100%;
  max-width: var(--sidebar-width);
  justify-content: space-between;
  position:fixed;
  overflow:hidden;
}

#sidebar h5 {
  padding: 0 0 0 1rem;
  color:#fff;
  margin-top:-0.1rem;
}

.sidebar-top .tappity-header {
  color:rgba(255,255,255,0.8);
  font-size: 0.8rem;
  font-weight:400;
  margin-bottom:1rem;
}

.sidebar-menu-item {
  flex: 0 1 3rem;
  font-size: 110%;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0.25rem 0;
  text-decoration: none;
  padding: 1rem;
}

.sidebar-item-selected {
  background-color: rgba(255,255,255,0.9);
  color: var(--primary-color);
}

button.sign-out-button {
  color: rgb(200, 200, 204);
  border-style: none;
  cursor: pointer;
  background:var(--primary-color)
}

.profile-topics-container {
  width: 50%;
  margin: 1rem auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.profile-topics-list {
  margin: 1rem 0;
  display: flex;
  flex-flow: row wrap;
  align-content:center;
  justify-content: center;
}

.profile-topic {
  font-weight: 600;
  color: black;
  padding: 0.5rem 1rem 0.7rem 1rem;
  margin: 0.3rem 0.3rem;
  background-color: white;
  border-style: solid;
  border-width: 0.15rem;
  border-radius: 1.25rem;
  border-color: orange;
  cursor: pointer;
  text-align: center;
  font-weight:700;
  font-size:1.2rem;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.24)
}
.topic-selected {
  background-color:orange;
  color: white;
  box-shadow: 0px 2px 6px rgba(0,0,0,0)
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .profile-topics-container {
		width: 90%;
  }
}

.update-password-email-container {
  padding: 1rem;
  margin-left: var(--sidebar-width);
  color: var(--dark-text-color);
  text-align: center;
}

.email-display {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-size: 200%;
}
#app-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100vh;
  max-width: 100%;
}

.app-page {
  margin-left: 240px;
  width: calc(100vw - 270px);
}

#main-content {
  order: 2;
  flex: 1;
  background-color: #f7f7f7;
}


.tappity-header {
  /* flex */
  font-size: 230%;
  font-weight: 800;
  color:white;
}

.tappity-sub-header {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 0 0rem 1rem;
}

/* tappity styled select */

.tappity-select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  color: white;
}

.tappity-select-wrapper {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid lightgray;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: var(--primary-color);
}

.tappity-select-wrapper select,
.tappity-select-wrapper::after {
  grid-area: select;
}

.tappity-select-wrapper:not(.tappity-select-wrapper--multiple)::after {
  content: "";
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: lightgray;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.tappity-select-wrapper:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

form.tappity-form {
  display: flex;
  flex-flow: column nowrap;
  max-width: 28rem;
  justify-content: center;
  margin: 5rem auto;
  
}

label.tappity-form-label {
  color: var(--primary-color);
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  font-size: large;
  font-weight: 600;
}
.tappity-form-input {
  font-size: medium;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.1rem;
  padding: 0.5rem 0.25rem;
  font-size: larger;
  border-color: darkgray;
}

.tappity-form-title {
  color: black;
  font-size: 200%;
  font-weight: 900;
  padding-bottom: 0.5rem;
  text-align: center;
}

.tappity-form .tappity-form-sub-title {
  color: black;
  font-size: 90%;
  padding-bottom: 0.5rem;
}

.tappity-form-submit-button {
  font-size: 120%;
  background-color: var(--primary-color);
  font-weight: 900;
  color: white;
  border: none;
  border-radius: 0.9rem;
  padding: 1rem 1.4rem;
  margin: 0.8rem 0;
  cursor: pointer;
  min-width: 5rem;
  box-shadow: 0px 6px 10px rgba(89, 49, 234, 0.4);
  display: inline-block;
}

.tappity-form-submit-button-inverted {
  font-size: 120%;
  background-color: white;
  font-weight: 900;
  color: var(--primary-color);
  border: none;
  border-radius: 0.9rem;
  padding: 1rem 1.4rem;
  margin: 0.8rem 0;
  cursor: pointer;
  min-width: 10rem;
  box-shadow: 0px 6px 10px rgba(89, 49, 234, 0.4);
  display: inline-block;
}
@keyframes buttonIsSubmitting {
  0% {
    background-color: var(--primary-color);
  }

  50% {
    background-color: var(--primary-color);
  }

  100% {
    background-color: var(--primary-color);
  }
}
button.tappity-form-submit-button:disabled {
  /* animation: buttonIsSubmitting 1.5s infinite; */
  background-color: #a393df;
  cursor: not-allowed;
}

div.tappity-form-error {
  background-color: #ffd0cd;
  color: #554b4b;
  border-radius: 1rem;
  border-color: #ff4e45;
  border-width: 0.1rem;
  margin: 0.2rem;
  padding: 0.5rem;
}

.purple-text {
  color: var(--primary-color);
}

.dark-text {
  color: var(--dark-text-color);
}
.gray-text {
  color: var(--dark-text-color);
  opacity: 0.7;
}

.centered-container {
  flex-flow: column nowrap;
  max-width: 28rem;
  display: flex;
  margin: 100px auto;
}

.loading-screen {
  background-image: url(loading.gif);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

.small-print {
  font-size: 0.9rem;
  opacity: 0.7;
  margin-bottom: 1rem;
  text-align: center;
}

.footer-buttons-container {
  margin-bottom: 2rem;
  text-align: center;
  color: black;
  margin-top:1rem;
}

.skip-container {
  display: flex;
  justify-content: center;
  color: var(--primary-color);
  font-weight: 700;
}
.promo-code-banner {
  background:orange;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  position: absolute;
  top: 0;
  left:0;
  width:calc(100% - 1.5rem);
  text-align: center;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.1);
}
.sign-up-terms-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.sign-up-terms-conditions-label {
  color: var(--dark-text-color);
}

.onboarding-logo {
  margin: 1rem;
}

.cta-form-error-message {
  font-size: 120%;
  color: #e73930;
  background-color: #fde9e7;
  border: 0.11rem solid #ff4e45;
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 0.5rem;
}

.prices-container {
  flex-direction: row;
  flex-wrap: nowrap;
}
.subscription-category-selector-container .tappity-header {
  margin-bottom: -0.5rem;
}
.category-container {
  background:white;
  border-radius: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
  max-width:34%;
  min-width: 34%;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
}

.price-options-container {
  border: .3rem solid var(--primary-color);
  border-radius: 1rem;
  padding: 2.5rem 0.5rem;
  background: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 2rem;
  align-items: left;
  margin-top:2rem;
}

.invisible {
  opacity: 0;
}
.category-price-preview {
  font-size: 0.8rem;
  color:#000;
}
.stripe-category-title {
  align-self: flex-end;
  background-color: var(--primary-color);
  text-align: right;
  font-size: 1.1rem;
  padding: 0.25rem 0.6rem ;
  border-radius: 0.8rem;
  margin: 0.3rem;
  font-weight: 500;
  z-index: 3;
}
.subscription-category-selector-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  
}
.marketing-category-text-container {
  margin: 0 2rem;
  display:inline-block;
}

.marketing-item-category-header {
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.marketing-item-category {
  color: black;
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  font-weight:500;
}

.category-container img {
  margin:-1rem auto 0 auto;
  width: 80%;
  z-index: 2;
}

.summer-product img {
  margin:-0 .5rem auto 0 auto;
  width: 100%;
  z-index: 2;
}

.subscription-categories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  
}

.price-selector-footer-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 2rem 2rem 2rem;
}

.lowest-price {
  font-weight:700;
  font-size: 1.7rem;
  
}

.category-footer {
  margin-top: 1.5rem;
  border-bottom-left-radius: 2.5%;
  border-bottom-right-radius: 2.5%;
  background-color: var(--light-primary-color);
  padding: 1.25rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:space-between;
  height: auto;
  
}

.featured-by-checkout {
  width:38%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #sidebar {
    display: none;
  }
  .category-container {
   
    border-radius: 2.5%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
    max-width:80%;
  }
  .app-page {
    margin: 1rem;
    width: calc(100vw - 2rem);
  }
  #stories-page nav {
    display: none;
  }
  .chip {
    zoom: 0.5;
  }
  .category-container {
    background:white;
    border-radius: 1rem;
    margin: 0rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
    width:500px;
    padding: 1rem;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
    margin-bottom: 2rem;
  }
  .featured-by-checkout {
    width:95%;
  }
  .marketing-category-text-container {
    margin: 0.5rem 0.5rem;
    display:inline-block;
  }

  .category-footer {
    margin-top: 0.5rem;
    border-bottom-left-radius: 2.5%;
    border-bottom-right-radius: 2.5%;
    background-color: white;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content:space-between;
    height: auto;
    
  }
}

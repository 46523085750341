#season-page header {
  position: relative;
  border-bottom: 1px solid #ccc;
  margin-left: 14px;
}

#season-page header h1 {
  font-size: 1.5rem;
}

#season-page header h2 {
  font-size: 0.9rem;
  color: black;
  font-weight: normal;
  margin-top: -0.5rem;
}

#season-page header a {
  margin-right: 0.6rem;
}

#season-page header .lesson-count-label {
  margin-left: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--primary-color);
  font-size: 80%;
}

#season-page .story-list {
  display: flex;
  flex-flow: row;
  align-content: flex-start;
  flex-wrap: wrap;
}

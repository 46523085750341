html {
  scroll-behavior: smooth;
}
header {
  background-size: cover;
  
}
#landing section,
#landing header,
#landing footer {
  padding: 1rem 1rem;
}

#landing header h1,
#landing header p {
  color: white;
}
#landing {
  color: black;
}
#landing p {
  color: #777;
}
#landing nav > *,
#landing footer > * {
  color: white;
}

#landing header {
  background-color: var(--primary-color);
}
#landing .stats {
  background-color: #b39a7c;
  padding:0.8rem;
}
#landing .classes {
  background-color: white;
}
#landing .praise {
  background-color: #f3f0fb;
}
#landing .lastcall {
  background-color: white;
}
#landing footer {
 
  background-color: #313e5c;
}

#landing .logo {
  margin: 0;
}

#landing header nav {
  z-index: 100;
}

#landing .signup-box {
  background-color: white;
  padding: 1rem;
  z-index: 10;
  position: relative;
}

#landing .signup-box p {
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem;
}
.landing-cta-form {
  text-align: center;
}

#landing .signup-box form {
  display: flex;
  flex-flow: column nowrap;
}

#landing .email-button-group {
  display: flex;
  flex-flow: row nowrap;
}

#landing .sign-up-button {
  min-width: 10rem;
}

#landing .sign-up-email {
  flex: 1;
}

#landing form input {
  font-size: 14pt;
  background-color: white;
  color: var(--primary-color);
  border-radius: 1rem;
  padding: 0.8rem;
  margin: 0.5rem;
  border: 1px solid var(--primary-color);
}

#landing form input:focus {
  outline: 0px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#landing form button {
  font-size: 1.4rem;
  background-color: #FF7500;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 0.9rem;
  padding: 0.8rem;
  margin: 0.5rem;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
}

#landing form button:focus {
  outline: 0px;
  /*border: 1px solid #ff9800;*/
  box-shadow: inset 0px 0px 10px #ff7500;
}

#landing .stats .inner-grid {
  color: white;
  display: grid;
  font-weight: bold;
  padding: 2rem 0;
}

#landing .stats .inner-grid .stat {
  display: block;
  font-size: 34pt;
  text-shadow: -1px 0px 4px rgb(0 0 0 / 25%);
  margin-bottom: 0.5rem;
}

#landing .stats .inner-grid .text {
  text-transform: uppercase;
}
h2 {
  margin: 0;
}
#landing .classes h2 {
  font-size: 3.5rem;
  font-weight: 800;
}

#landing .classes p {
  color: black;
  font-size: 1.4rem;
  margin: 0;
  margin-top:1rem;
}

#landing .promise {
  text-align: center;
}

#landing .promise h2 {
  color: #ffc920;
  text-transform: uppercase;
  font-size: 12pt;
}

#landing .promise p {
  color: white;
  font-size: 16pt;
}

#landing .praise .inner-grid {
  display: grid;
}

#landing .praise .review {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1.5rem 0;
}

#landing .praise .review img {
  width: 32px;
  height: 32px;
  background-color: gray;
  border-radius: 32px;
  margin-right: 12px;
  float: left;
}

#landing .praise .review .firstline {
  font-size: 11pt;
}

#landing .praise .review .parentname::after {
  content: "💬";
  margin: 0 0.2rem;
}

#landing .praise .review .parentname,
#landing .praise .review .tappity {
  font-weight: bold;
}

#landing .praise .review .date {
  display: block;
  color: #999;
  font-size: 9pt;
}

#landing .praise .review .content {
  clear: left;
  margin-top: 0.5rem;
  font-size: 11pt;
}

#landing .praise .review .likes {
  margin-top: 0.5rem;
  font-size: 9pt;
}

#landing .praise .review .likes::before {
  content: "👍";
  margin-right: 0.5rem;
}

#landing .praise h2 {
  font-size: 3.5rem;
  font-weight:800;
  margin-bottom: 0;
  margin-top: 20%;
}

#landing .praise p {
  font-size: 18pt;
  margin-right: 3rem;
}

#landing .lastcall h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top:2rem;
}

#landing .lastcall p {
  color: var(--primary-color);
  text-transform: uppercase;
  margin-top: 1rem;
  font-weight: bold;
  
}

#landing footer {
  padding: 5rem 2rem;
}

#landing footer p {
  font-size: 10pt;
  margin-top: 4rem;
}

.hero-text h1 {
  font-size:4rem;
  margin:1rem 0 0 0;
}

#landing .logos {
  align-items: center;
  background: white;
  padding:0;
}


.logos img {
  width: 100%;
  margin: 4rem 0 0 0;
  padding: 0;
}


@media only screen and (min-width: 769px) {
  /* For larger screens: */
  #landing section,
  #landing header,
  #landing footer {
    display: grid;
    grid-template-columns: minmax(650px, 1000px);
    justify-content: center;
    padding: 5rem;
    z-index: 10;
    position: relative;
  }

  #landing header nav {
    /*width: calc(100% + 40px);
        margin-left: -20px;*/
    margin-bottom: 4em;
  }

  #landing header nav h1 {
    float: left;
  }
  #landing header nav a {
    float: right;
    font-size: 0.9rem;
  }

  #landing header {
    background-position: top -120px right -30px, bottom 80px left 30px;
    background-repeat: no-repeat;
    padding-bottom: 300px;
  }

  #landing .hero-text {
    max-width: 50%;
  }
 
  #landing .hero-text p {
    font-size: 1.5rem;
  }

  #landing .signup-box {
    width: 600px;
    margin: 2rem auto;
    margin-top: -220px;
    top:-50px;
    border-radius: 1rem;
    box-shadow: -1px 10px 15px rgb(0 0 0 / 20%);
  }

  #landing .signup-box p {
    margin-top: 0;
  }

  #landing .stats .inner-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
  }

  #landing .classes {
    padding-bottom: 6rem;
  }

  #landing .classes h2 {
    text-align: center;
    padding: 0 15%;
    margin-top: 3rem;
  }

  #landing .classes p {
    text-align: center;
    padding: 0 15%;
  }



  #landing .promise div {
    margin: auto;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    box-shadow: -1px 5px 10px rgb(0 0 0 / 30%);
  }

  #landing .promise {
    z-index: 100;
    margin: auto;
    margin-top: -5rem;
    max-width: 42rem;
  }

  #landing .promise div {
    background-color: var(--primary-color);
    padding: 0.5rem 2rem;
  }

  #landing .promise h2 {
    padding: 0 3rem;
  }
  #landing .promise p {
    padding: 0 3rem;
  }

  #landing .praise .inner-grid {
    grid-template-columns: 1fr 1fr;
  }

  #landing .praise .inner-grid > div {
    margin: 0 3rem;
  }

  #landing .lastcall {
    text-align: center;
    padding-bottom: 3rem;
  }

  #landing .lastcall form {
    margin: 1rem 10rem;
  }

  #landing footer nav a {
    float: right;
    clear: right;
    margin: 0.25rem 0;
  }
  #landing footer nav {
    margin-top: -3rem;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #landing header nav {
    background-color: var(--primary-color);
  }

  #landing header nav a {
    display: none;
  }

  #landing header {
    background-position: bottom -150px center, bottom -200px left -200px;
    background-repeat: no-repeat;
    background-size: cover, auto;
  }

  
  #landing .hero-text p {
    font-size: 1.5rem;
    max-width: 85%;
  }
  #landing .hero-text {
    margin: -1.5rem;
    padding: 2rem 1.5rem 15rem 1.5rem;
    background-image: linear-gradient(var(--primary-color), transparent);
  }

  #landing .email-button-group {
    display: flex;
    flex-flow: column nowrap;
  }

  #landing .terms-conditions-label {
    font-size: 70%;
    vertical-align: middle;
    font-weight: 500;
  }

  #landing .signup-box p {
    margin: 1rem;
  }

  #landing .stats .inner-grid {
    grid-template-columns: 1fr;
    text-align: left;
  }

  #landing .stats .inner-grid div {
    padding-bottom: 1.5rem;
    padding-left: 2rem;
  }

  #landing .stats .inner-grid .stat {
    font-size: 38pt;
  }

  #landing .stats .inner-grid .text {
    font-size: 14pt;
  }

  #landing .classes h2 {
    margin-top: 0;
  }

  #landing .promise {
    background-color: var(--primary-color);
  }

  #landing .promise p {
    padding: 0 2rem;
  }

  #landing .praise .inner-grid .reviewList {
    order: 2;
  }

  #landing .praise h2 {
    margin-top: 0;
  }

  #landing .lastcall h2 {
    margin-top: 0;
  }

  #landing footer nav a {
    display: block;
    margin: 0.5rem 0;
    font-size: 15pt;
  }

  #landing footer nav {
    margin-top: 1rem;
    margin-bottom: 8rem;
  }
  #landing .lastcall {
    text-align: center;
  }
}

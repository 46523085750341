.carousel {
  position: relative;
}

.carousel .carousel-wrapper {
  display: grid;
  grid-template-columns: 10px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  overflow-x: scroll;
  overflow-y: visible;
  scroll-behavior: smooth;
}

.carousel-wrapper::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.carousel .carousel-wrapper:before,
.carousel .carousel-wrapper:after {
  content: "";
  width: 10px;
}

.carousel .scroll-left,
.carousel .scroll-right {
  font-size: 24pt;
  background-color: white;
  border: 3px solid var(--primary-color);
  position: absolute;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  color: var(--primary-color);
  font-weight: bold;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 50%);
  top: calc(50% - 1.5rem);
  cursor: pointer;
}

.carousel .scroll-right {
  right: 0.5em;
}

.carousel .scroll-left {
  left: 0.5em;
}

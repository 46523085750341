body {
  background-color: var(--primary-color);
}
.subscription-manager-container {
  /* margin-left: var(--sidebar-width); */
  color: var(--dark-text-color);
  /* text-align: center; */
  padding: 0rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.strike-through {
  text-decoration:line-through;
  font-weight: 400;
  font-size:1.4rem;
}

.plan-page-header-container {
text-align: center;
color:#fff;
margin-bottom:1rem;
}

.subscription-status-container {
  /* margin-left: var(--sidebar-width); */
  color: var(--dark-text-color);
  /* text-align: center; */
  padding: 0rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;
  max-width: 60%;
  margin: 2rem 2rem 2rem 250px;
  padding: 2rem;
}

.subscription-price-options {
  border-radius: 0.2rem 0 0 0.2rem;
  overflow: hidden;

  /* box-shadow: 2px 2px 3px #888, -2px 2px 3px #888; */
  overflow: visible;
  z-index: 1000;
}

.price-selector-subheader {
  margin: 1rem;
}
.subscription-price-container {
  display: flex;
  flex-flow: column;
  justify-content: left;
  margin-bottom: 2rem;
  padding: 1rem 2rem 0rem 2rem;

}

.subscription-manager-container .tappity-header {
  margin-bottom: 2rem;
}
.marketing-content {
  background-color: white;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.12);
}

.list-icon {
  top: 6px;
  position: relative;
  color: var(--primary-color);
}
.marketing-item {
  margin-top: 7px;
  opacity: 0.8;
}
.stripe-price-details {
  display: flex;
  background-color: clear;
  opacity: 0.75;
  border-radius: 0.5rem;
  padding: 1.2rem 1.5rem;
  margin-top: 1rem;
  text-align: left;
  justify-content: space-between;
  cursor: pointer;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); */
  border: 0.2rem solid grey;
}
.price-title {
  font-size:1.2rem;
  font-weight:700
}
.price-selected {
  background-color: white;
  color: black;
  opacity: 1;
  border: 0.2rem solid var(--primary-color);;
  /* border-radius: 0px 0px 0px 0px; */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  /* border: 0.1rem solid var(--primary-color); */
}
.price-selector-header {
  margin: 1rem 0 -1rem 0;
  font-weight: 700;
}

.free-classes-banner {
  font-size: 0.9rem;
  background: var(--primary-color);
  width: auto;
  display: inline-block;
  padding: 0.25rem 0.8rem;
  color: white;
  border-radius: 1rem;
  font-weight: 600;
}

.stripe-price-nickname {
  font-size: 0.5rem;
  color: white;
  background-color: orange;
  border-radius: 0.5rem;
  padding: 0.3rem 0;
  letter-spacing: 0.05rem;
  font-weight: 700;
  margin-bottom: 0rem;
  width: auto;
  text-align: center;
}
.price-selected .stripe-price-nickname {
  visibility: visible;
}

.stripe-price-amount {
  font-size: 1.5rem;
  font-weight: 700;
}
.highlighter {
  background: #FFFF42;
}
.stripe-price-amount-coupon-applied {
  text-decoration: line-through;
}

.stripe-price-interval {
  /* color: var(--light-text-color); */
  font-size: 1rem;
  margin-right:2rem;
}

.marketing-content {
  text-align: left;
  padding: 1.5rem 2.5rem 1rem 2.5rem;
  border-radius: 0 0.2rem 0.2rem 0;
}
.marketing-header {
  font-weight: 600;
  font-size: 130%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  /* .subscription-price-container {
    flex-flow: column nowrap;
  } */
  /* .subscription-price-options {
    border-radius: 1rem 1rem 0 0;
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
    margin: 0;
    box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.1);
  } */
  .marketing-content {
    text-align: left;
    padding: 2rem;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 1rem 1rem;
  }

  .marketing-header {
    font-weight: 700;
    font-size: 140%;
  }

  .stripe-price-amount {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .stripe-price-interval {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  /* .stripe-price-details {
    padding: 0.5rem 0.15rem;
    margin: 0;
  } */
}

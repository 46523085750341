.progress-update-emails-manager-container {
  padding: 1rem;
  margin-left: var(--sidebar-width);
  color: var(--dark-text-color);
  text-align: center;
}
.tappity-header {
  margin-bottom:8px;
}
.email-frequency-dropdown {
  margin-top:1rem;
  font-size: large;
  padding:0.5rem;
  font-weight:700;
  border:2px solid var(--primary-color);
  border-radius: 0.5rem;
  color: var(--primary-color);
}

#stories-page header {
  position: relative;

  margin-left: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
}

#stories-page header h1 {
  font-size: 1.7rem;
}

#stories-page header h2 {
  font-size: 1.1rem;
  color: black;
  font-weight: normal;
  margin-top: -0.5rem;
}

#stories-page header nav {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

#tag-list-wrapper h3 {
  color: black;
  font-weight: 900;
  margin-left: 14px;
  margin-bottom: 6px;
  font-size: 1.3em;
}

.carousel-wrapper {
  margin-left: -29px;
}

#stories-page .no-lessons {
  font-size: 150%;
  color: var(--primary-color);
  padding: 1rem;
}

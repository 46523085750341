.live-story-player {
  height: 97%;
  overflow: hidden;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
  margin-left: calc(var(--sidebar-width) + 70px);
  border-radius: 1rem;
  margin-top: 1%;
  max-width: 70%;
}

.live-story-player video {
  height: 130%;
  margin-top: -15%;
  z-index: 10;
}

.live-story-player .node-container {
  all: none;
  width: 100%;
  height: 50%;
  z-index: 1000;
  margin-top: -94%;
  position: relative;
  background: rgba(0, 0, 0, 0);
}

.live-story-player .selfie-node-container {
  height: 100%;
  margin-top: -160%;
  flex-flow: column nowrap;
}

.live-image-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: inline;
  background: rgba(0, 0, 0, 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.live-story-player .node-container .live-node-interaction-panel {
  height: 95%;
  width: 100%;
  position: absolute;
  display: inline-flex;
}

.option-item {
  position: relative;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 5em;
  height: 5em;
  z-index: 1200;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  display: inline-block;
  margin-right: 1em;
}
.option-items-container {
  display: inline-block;
  position: relative;
  margin-left: calc(50% - 5.25em);
  text-align: center;
  align-content: center;
}
.live-story-player .node-container .live-node-interaction-panel .option-item {
  position: relative;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 20%;
  height: 30%;
  z-index: 1200;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
}

.two-options {
  margin: 30% 15% 10% 15%;
}
.three-options {
  margin: 25% 8% 5% 5%;
}

.video-play-button {
  z-index: 50;
  cursor: pointer;
  height: 20%;
  width: auto;
  position: absolute;
  top: 40%;
  transform: translateX(130%);
}

.node-container > .webcam-mirror {
  height: auto;
  margin-top: -2em;
}

.selfie-image {
  margin: auto;
}

.webcam-mirror > canvas {
  margin: auto 0;
}

@media only screen and (max-width: 768px) {
  .video-play-button {
    left: 0;
    transform: translateX(100%);
  }
  .node-container > .webcam-mirror {
    height: 100%;
    margin-top: -100%;
  }
}

.selfie-interaction-panel {
  padding: 2em;
  justify-content: center;
  background: #fff;
  margin-top: -5px;
  padding-bottom: 10em;
}

.up-down-button {
  width: var(--node-option-width);
  height: var(--node-option-width);
  background-size: cover;
  border-radius: calc(var(--node-option-width) * 0.5);
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.selfie-button {
  height: calc(var(--node-option-width) - var(--node-option-width) / 3);
  width: var(--node-option-width);
  text-align: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  padding-top: calc(var(--node-option-width) / 3);
  font-size: 3em;
}

.true-option {
  background-color: var(--green-color);
}

.false-option {
  background-color: var(--red-color);
}

.playback-node-results-container {
  display: inline;
  width: 100%;
  height: 50%;
}

.option-votes-container {
  display: inline-block;
  position: relative;
  margin: 30px 0px 0px 30px;
  vertical-align: text-bottom;
  margin-top: 58%;
  transition: margin-top 4s !important;
}

.option-1-votes-body,
.option-2-votes-body {
  width: 50px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  transition: height 4s !important;
  height: 0px;
}

.option-1-votes-head,
.option-2-votes-head {
  padding: 0.3rem;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 800;
  margin-bottom: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.true-false-question-results .option-1-votes-head {
  background-color: var(--green-color);
}

.true-false-question-results .option-2-votes-head {
  background-color: var(--red-color);
}

.true-false-question-results .option-1-votes-body {
  background-color: var(--green-color);
}

.true-false-question-results .option-2-votes-body {
  background-color: var(--red-color);
}

.activate-camera-button {
  background: var(--primary-color);
  border-radius: 1rem;
  padding: 0.5em 0.9em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
  border: none;
  font-size: 1.5em;
  margin-top: 20px;
}

.live-story-player .live-builder-node-container {
  width: 100%;
  height: 100%;
  z-index: 1000;
  margin-top: -163%;
  position: relative;
  background-image: url(https://assetcdn.tappityapp.com/image/wood-bg-1.jpg);
}

.live-builder-node-background-image {
  width: 100%;
  position: absolute;
  display: none;
}

.live-builder-option {
  display: none;
}

.prev-builder-selection {
  display: none;
}

.live-builder-node-canvas {
  width: 100%;
  position: absolute;
  display: block;
  touch-action: none;
}

.live-builder-node-interaction-panel {
  width: 100%;
  margin-top: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  height: 25%;
}

.builder-option-item {
  margin: 2%;
  flex-basis: 100%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1200;
  cursor: pointer;
}

.live-builder-centered-option {
  margin: 5% 0;
  flex-basis: 25%;
  background-color: var(--green-color);
  border-radius: 50%;
  background-image: url(https://assetcdn.tappityapp.com/image/thumbs-up-button.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1200;
  cursor: pointer;
}

#story-filters .filter-button > button {
  margin-left: 1rem;
  color: var(--primary-color);
  background-color: white;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #eee;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.1em;
}

#story-filters .filter-button > button:active {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid #ccc;
}

#story-filters .filter-button > button.filterActive {
  background-color: var(--primary-color);
  color: white;
}

#story-filters .filter-button > button.filterActive:active {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 1);
}

.filter-button {
  position: relative;
}

.clear-filters {
  position: absolute;
  top: -1.5rem;
  right: 0;
  border: 0;
  background: none;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
}

.dropdown-card {
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: 0;
  min-width: 350px;
  border-radius: 1rem;
  background-color: var(--primary-color);
  z-index: 100000;
  padding: 0.5rem;
}

.dropdown-item {
  padding: 0.5rem;
}

.dropdown-close {
  position: absolute;
  top: 0;
  right: 0.2rem;
  background: none;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 110%;
  cursor: pointer;
  padding: 0.5rem;
}

.dropdown-subtitle {
  padding: 1rem;
}

.dropdown-item label {
  padding-left: 0.2rem;
}

.tinkerers-filter .dropdown-card {
  width: 540px;
}

.tinkerers-filter .dropdown-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.tinkerers-filter .dropdown-item {
  width: 6rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.7rem;
}

.tinkerers-filter .dropdown-item label {
  max-width: 4rem;
  display: inline-block;
}

.filter-item {
  cursor: pointer;
}

.tinkerers-filter .profile-image img {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
}

.tinkerers-filter .profile-image {
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  border: 2px solid white;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

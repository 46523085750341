.badge-earning-background-image {
  /* height: 100%; */
  width: 100%;
  height:205%;
  background-position: center;
  /* background-size: 100%; */
  background-clip: content-box;
  display:inline;
  position:absolute;
  top:-102%;
  /* display: none; */

}

.full-node-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  z-index: 1000;
  margin-top: -175%;
  position: relative;
  background: rgba(192, 45, 45, 1);
}

.badge-image {
  display: block;
  position: relative;
  margin: 0px auto;
  margin-top:10%;
}

.badge-image {
  max-width: 100%;
  max-height: 50%;
}
.live-story-player .full-node-container .live-node-interaction-panel .centered-option {
  margin: 30% 40% 10% 40% !important;
}
.checkout-success-container {
  color: white;
  text-align: center;
  padding:2rem 20%;
  align-items: center;

}

.checkout-success-container div{
  display:inline-block;
}

.checkout-success-container .right-side {
  width: 40%;
}

.right-side img {
  width:64%;
  margin-left: 18%;
}

.checkout-success-container h1 {
  font-size:3rem;
  color:white;
  margin-top:2rem;
}

.checkout-success-container h3 {
  font-size:1.4rem;
}

.solid {
  margin: 3rem 0 1rem 0;
  opacity: 0.3;
}

ul {
  list-style-type: none;
  font-size:1.4rem;
  font-weight:300;
  margin:0;
  padding:0;
}

.footer {
  margin-top: 4rem;
}

p {
  color:white;
}

li {
  margin-bottom:0.7rem;
}

.left-side {
  width:60%;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .checkout-success-container {
    margin-left: 0;
    padding: 1rem;
  }
  .left-side {
    width:100%;
    text-align: left;
  }
  .right-size {
    width:60%;
  }
  .right-side img {
    width:100%;
    margin: 3rem auto;
  }
}


#user-profile-list {
  display: flex;
  flex-flow: column nowrap;
  max-width: 25rem;
  margin: 5rem auto;
}

.user-profile-list-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.child-profiles {
  font-size: 160%;
  font-weight: 700;
  color: black;
}

button.add-child-profile {
  color: white;
  background-color: var(--primary-color);
  border-style: none;
  border-radius: 0.5rem;
  font-size: 100%;
  font-weight: 700;
  padding: 0.5rem 1rem;
  cursor: pointer;
  position:relative;
  display:inline-block;
}

h1 {
  font-weight:900;
  color: #000;
}

p {
  color: #000;
}